#menu-toggle:checked+#menu {
   display: block;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type=number] {
   -moz-appearance: textfield;
}

.jumbotron {
   height: 450px;
}

.ReactModal__Overlay {
   opacity: 0;
   transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
   opacity: 1;
}

.ReactModal__Overlay--before-close {
   opacity: 0;
}

.login-modal {
   background: rgba(0, 0, 0, 0.542);

}

.login-modal-content,
.edit-profile-modal-content,
.edit-education-modal-content,
.edit-company-modal-content {
   position: absolute;
   top: 20px;
   left: 20px;
   right: 20px;
   /* bottom: 20px; */
   background-color: #fff;
   border-radius: 0.5rem;
   padding: 1.25rem;
   overflow: scroll;
   margin-bottom: 20px;
}

.password-modal-content {
   overflow: visible !important;

}

.edit-education-modal-content,
.edit-profile-modal-content {
   bottom: 0;

}


.login-modal-content:focus,
.edit-profile-modal-content:focus,
.edit-education-modal-content:focus,
.edit-company-modal-content:focus {
   outline: none;
}

.register-container {
   max-width: 768px;
}

.seelect-option>div {
   border: 1px solid #a0aec0;
   height: 43px;

}

.jobdesk-container h1,
.jobdesk-container h2,
.jobdesk-container h3,
.jobdesk-container h4,
.jobdesk-container h5 {
   font-size: 1rem !important;
   font-weight: bold !important;
   padding: 0.5rem 0;
}

.jobdesk-container ul {
   list-style: disc;
   padding-left: 2.5rem;
}


.job-detail-footer::after {
   content: '·';
   padding: 0 6px;
}

.drop-down-wrapper {
   max-height: 24rem;
   overflow: scroll;
}

.user-avatar-container:hover .user-avatar {
   opacity: 0.5;
}

.user-avatar-container:hover .button-avatar {
   opacity: 1;
}

.button-avatar {
   top: 50%;
   left: 50%;
   opacity: 0;
   transition: 200ms;
}

/* middle */
@media (min-width: 768px) {
   .login-modal-content {
      max-width: 460px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
   }

   .edit-profile-modal-content,
   .edit-profile-modal-content {
      max-width: 768px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
   }



}

/* large */
@media (min-width: 1024px) {

   .edit-education-modal-content,
   .edit-profile-modal-content,
   .edit-company-modal-content {
      max-width: 850px;
      overflow: scroll;
      top: 18%;
      left: 30%;
      transform: translate(-20%, -20%);
   }
}

/* small */
@media (max-width: 640px) {
   .register-container {
      max-width: 360px;
   }



}